import { default as analyticseofTjBoBMoMeta } from "/usr/src/app/pages/admin/analytics.vue?macro=true";
import { default as diffusion_45modelxBV9l6qJtaMeta } from "/usr/src/app/pages/admin/create/diffusion-model.vue?macro=true";
import { default as indexrboc6vLlwvMeta } from "/usr/src/app/pages/admin/create/index.vue?macro=true";
import { default as feedbackskYwOe86nVoMeta } from "/usr/src/app/pages/admin/feedbacks.vue?macro=true";
import { default as indexgKB99wg3ORMeta } from "/usr/src/app/pages/admin/index.vue?macro=true";
import { default as modelsqay20rYOpIMeta } from "/usr/src/app/pages/admin/models.vue?macro=true";
import { default as indexr34v9H72cVMeta } from "/usr/src/app/pages/admin/plans/index.vue?macro=true";
import { default as manage_45planROPDVCt39BMeta } from "/usr/src/app/pages/admin/plans/manage-plan.vue?macro=true";
import { default as _91id_939ml6QSl7D6Meta } from "/usr/src/app/pages/admin/presets/[id].vue?macro=true";
import { default as indexs2zL4RJym5Meta } from "/usr/src/app/pages/admin/presets/index.vue?macro=true";
import { default as cancel_45newsletterun8c9VhMcDMeta } from "/usr/src/app/pages/cancel-newsletter.vue?macro=true";
import { default as indexJPEr6nErnHMeta } from "/usr/src/app/pages/checkout/index.vue?macro=true";
import { default as payment_45selectJWYuNlLPqdMeta } from "/usr/src/app/pages/checkout/payment-select.vue?macro=true";
import { default as plansG90VXJKg22Meta } from "/usr/src/app/pages/checkout/plans.vue?macro=true";
import { default as _91token_93lDjjU460wvMeta } from "/usr/src/app/pages/confirm-delete-user/[token].vue?macro=true";
import { default as create_45accountUPDxhDhF00Meta } from "/usr/src/app/pages/create-account.vue?macro=true";
import { default as explorejnVQZ9Va3uMeta } from "/usr/src/app/pages/explore.vue?macro=true";
import { default as forget_45passwordOIc1GJwnKAMeta } from "/usr/src/app/pages/forget-password.vue?macro=true";
import { default as _91id_93Fh089LF2bMMeta } from "/usr/src/app/pages/images/[id].vue?macro=true";
import { default as create82iS9HuMe9Meta } from "/usr/src/app/pages/images/create.vue?macro=true";
import { default as libraryFUTfjYlfdVMeta } from "/usr/src/app/pages/images/library.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as lpZWpqBd3aPHMeta } from "/usr/src/app/pages/lp.vue?macro=true";
import { default as balanceHPZbMmbyr6Meta } from "/usr/src/app/pages/my-account/balance.vue?macro=true";
import { default as indexI8SCkr0qzIMeta } from "/usr/src/app/pages/my-account/index.vue?macro=true";
import { default as language2L7yg851eLMeta } from "/usr/src/app/pages/my-account/language.vue?macro=true";
import { default as library1AeRUHnY6CMeta } from "/usr/src/app/pages/my-account/library.vue?macro=true";
import { default as menuemNyI6dExpMeta } from "/usr/src/app/pages/my-account/menu.vue?macro=true";
import { default as _91id_93WezdEyQsh2Meta } from "/usr/src/app/pages/my-account/models/[id].vue?macro=true";
import { default as indexHP5nEAbRFCMeta } from "/usr/src/app/pages/my-account/models/index.vue?macro=true";
import { default as notificationse7SVfSetzsMeta } from "/usr/src/app/pages/my-account/notifications.vue?macro=true";
import { default as deleted9fYHJaKNuMeta } from "/usr/src/app/pages/my-account/settings/delete.vue?macro=true";
import { default as indexiecjt0BlMkMeta } from "/usr/src/app/pages/my-account/settings/index.vue?macro=true";
import { default as subscriptionSWoNsQdb8VMeta } from "/usr/src/app/pages/my-account/subscription.vue?macro=true";
import { default as _91id_93NSuo78nTVqMeta } from "/usr/src/app/pages/new-avatar/[id].vue?macro=true";
import { default as be_45surprisedCmwAYvAhp7Meta } from "/usr/src/app/pages/new-avatar/be-surprised.vue?macro=true";
import { default as libraryQ92kXT3SYsMeta } from "/usr/src/app/pages/new-avatar/library.vue?macro=true";
import { default as privacy_45policy1IN0o4tDn5Meta } from "/usr/src/app/pages/privacy-policy.vue?macro=true";
import { default as redefine_45passwordlXgeAVGnIlMeta } from "/usr/src/app/pages/redefine-password.vue?macro=true";
import { default as _91search_93F0pjly6awsMeta } from "/usr/src/app/pages/research/[search].vue?macro=true";
import { default as _91id_93nnaaOj5rqsMeta } from "/usr/src/app/pages/research/gallery/[id].vue?macro=true";
import { default as indexwHQHwNztfjMeta } from "/usr/src/app/pages/research/gallery/index.vue?macro=true";
import { default as indexzA56lngK70Meta } from "/usr/src/app/pages/research/index.vue?macro=true";
import { default as colorize_45image4N4M43lEgxMeta } from "/usr/src/app/pages/tools/colorize-image.vue?macro=true";
import { default as drawing_45to_45artP6MJWM9Z6iMeta } from "/usr/src/app/pages/tools/drawing-to-art.vue?macro=true";
import { default as free_45avatarmNzbx1H8J6Meta } from "/usr/src/app/pages/tools/free-avatar.vue?macro=true";
import { default as generate_45babyCVtlwRNh3bMeta } from "/usr/src/app/pages/tools/generate-baby.vue?macro=true";
import { default as generate_45caption2i4dFtkp10Meta } from "/usr/src/app/pages/tools/generate-caption.vue?macro=true";
import { default as generate_45logo_45analysishQ0NUjoWS1Meta } from "/usr/src/app/pages/tools/generate-logo-analysis.vue?macro=true";
import { default as generate_45prompt_45from_45image3w5WgnzkhrMeta } from "/usr/src/app/pages/tools/generate-prompt-from-image.vue?macro=true";
import { default as generate_45social_45media_45post_45analysisTmYuKklUdXMeta } from "/usr/src/app/pages/tools/generate-social-media-post-analysis.vue?macro=true";
import { default as indexDoArbnIAQHMeta } from "/usr/src/app/pages/tools/index.vue?macro=true";
import { default as remove_45backgroundHecQJgHE8MMeta } from "/usr/src/app/pages/tools/remove-background.vue?macro=true";
import { default as restore_45imagevt7l0OCfUbMeta } from "/usr/src/app/pages/tools/restore-image.vue?macro=true";
import { default as stickerMwgFuUiLNOMeta } from "/usr/src/app/pages/tools/sticker.vue?macro=true";
import { default as welcomeGJTn4hmw0jMeta } from "/usr/src/app/pages/welcome.vue?macro=true";
export default [
  {
    name: "admin-analytics",
    path: "/admin/analytics",
    meta: analyticseofTjBoBMoMeta || {},
    component: () => import("/usr/src/app/pages/admin/analytics.vue").then(m => m.default || m)
  },
  {
    name: "admin-create-diffusion-model",
    path: "/admin/create/diffusion-model",
    meta: diffusion_45modelxBV9l6qJtaMeta || {},
    component: () => import("/usr/src/app/pages/admin/create/diffusion-model.vue").then(m => m.default || m)
  },
  {
    name: "admin-create",
    path: "/admin/create",
    meta: indexrboc6vLlwvMeta || {},
    component: () => import("/usr/src/app/pages/admin/create/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-feedbacks",
    path: "/admin/feedbacks",
    meta: feedbackskYwOe86nVoMeta || {},
    component: () => import("/usr/src/app/pages/admin/feedbacks.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexgKB99wg3ORMeta || {},
    component: () => import("/usr/src/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-models",
    path: "/admin/models",
    meta: modelsqay20rYOpIMeta || {},
    component: () => import("/usr/src/app/pages/admin/models.vue").then(m => m.default || m)
  },
  {
    name: "admin-plans",
    path: "/admin/plans",
    meta: indexr34v9H72cVMeta || {},
    component: () => import("/usr/src/app/pages/admin/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-plans-manage-plan",
    path: "/admin/plans/manage-plan",
    meta: manage_45planROPDVCt39BMeta || {},
    component: () => import("/usr/src/app/pages/admin/plans/manage-plan.vue").then(m => m.default || m)
  },
  {
    name: "admin-presets-id",
    path: "/admin/presets/:id()",
    meta: _91id_939ml6QSl7D6Meta || {},
    component: () => import("/usr/src/app/pages/admin/presets/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-presets",
    path: "/admin/presets",
    meta: indexs2zL4RJym5Meta || {},
    component: () => import("/usr/src/app/pages/admin/presets/index.vue").then(m => m.default || m)
  },
  {
    name: "cancel-newsletter",
    path: "/cancel-newsletter",
    component: () => import("/usr/src/app/pages/cancel-newsletter.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/usr/src/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-payment-select",
    path: "/checkout/payment-select",
    component: () => import("/usr/src/app/pages/checkout/payment-select.vue").then(m => m.default || m)
  },
  {
    name: "checkout-plans",
    path: "/checkout/plans",
    meta: plansG90VXJKg22Meta || {},
    component: () => import("/usr/src/app/pages/checkout/plans.vue").then(m => m.default || m)
  },
  {
    name: "confirm-delete-user-token",
    path: "/confirm-delete-user/:token()",
    component: () => import("/usr/src/app/pages/confirm-delete-user/[token].vue").then(m => m.default || m)
  },
  {
    name: "create-account",
    path: "/create-account",
    meta: create_45accountUPDxhDhF00Meta || {},
    component: () => import("/usr/src/app/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/usr/src/app/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "forget-password",
    path: "/forget-password",
    meta: forget_45passwordOIc1GJwnKAMeta || {},
    component: () => import("/usr/src/app/pages/forget-password.vue").then(m => m.default || m)
  },
  {
    name: "images-id",
    path: "/images/:id()",
    component: () => import("/usr/src/app/pages/images/[id].vue").then(m => m.default || m)
  },
  {
    name: "images-create",
    path: "/images/create",
    meta: create82iS9HuMe9Meta || {},
    component: () => import("/usr/src/app/pages/images/create.vue").then(m => m.default || m)
  },
  {
    name: "images-library",
    path: "/images/library",
    meta: libraryFUTfjYlfdVMeta || {},
    component: () => import("/usr/src/app/pages/images/library.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "lp",
    path: "/lp",
    meta: lpZWpqBd3aPHMeta || {},
    component: () => import("/usr/src/app/pages/lp.vue").then(m => m.default || m)
  },
  {
    name: "my-account-balance",
    path: "/my-account/balance",
    meta: balanceHPZbMmbyr6Meta || {},
    component: () => import("/usr/src/app/pages/my-account/balance.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my-account",
    component: () => import("/usr/src/app/pages/my-account/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-language",
    path: "/my-account/language",
    meta: language2L7yg851eLMeta || {},
    component: () => import("/usr/src/app/pages/my-account/language.vue").then(m => m.default || m)
  },
  {
    name: "my-account-library",
    path: "/my-account/library",
    component: () => import("/usr/src/app/pages/my-account/library.vue").then(m => m.default || m)
  },
  {
    name: "my-account-menu",
    path: "/my-account/menu",
    meta: menuemNyI6dExpMeta || {},
    component: () => import("/usr/src/app/pages/my-account/menu.vue").then(m => m.default || m)
  },
  {
    name: "my-account-models-id",
    path: "/my-account/models/:id()",
    component: () => import("/usr/src/app/pages/my-account/models/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-account-models",
    path: "/my-account/models",
    meta: indexHP5nEAbRFCMeta || {},
    component: () => import("/usr/src/app/pages/my-account/models/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-notifications",
    path: "/my-account/notifications",
    meta: notificationse7SVfSetzsMeta || {},
    component: () => import("/usr/src/app/pages/my-account/notifications.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings-delete",
    path: "/my-account/settings/delete",
    meta: deleted9fYHJaKNuMeta || {},
    component: () => import("/usr/src/app/pages/my-account/settings/delete.vue").then(m => m.default || m)
  },
  {
    name: "my-account-settings",
    path: "/my-account/settings",
    meta: indexiecjt0BlMkMeta || {},
    component: () => import("/usr/src/app/pages/my-account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "my-account-subscription",
    path: "/my-account/subscription",
    meta: subscriptionSWoNsQdb8VMeta || {},
    component: () => import("/usr/src/app/pages/my-account/subscription.vue").then(m => m.default || m)
  },
  {
    name: "new-avatar-id",
    path: "/new-avatar/:id()",
    component: () => import("/usr/src/app/pages/new-avatar/[id].vue").then(m => m.default || m)
  },
  {
    name: "new-avatar-be-surprised",
    path: "/new-avatar/be-surprised",
    component: () => import("/usr/src/app/pages/new-avatar/be-surprised.vue").then(m => m.default || m)
  },
  {
    name: "new-avatar-library",
    path: "/new-avatar/library",
    component: () => import("/usr/src/app/pages/new-avatar/library.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/usr/src/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "redefine-password",
    path: "/redefine-password",
    meta: redefine_45passwordlXgeAVGnIlMeta || {},
    component: () => import("/usr/src/app/pages/redefine-password.vue").then(m => m.default || m)
  },
  {
    name: "research-search",
    path: "/research/:search()",
    component: () => import("/usr/src/app/pages/research/[search].vue").then(m => m.default || m)
  },
  {
    name: "research-gallery-id",
    path: "/research/gallery/:id()",
    component: () => import("/usr/src/app/pages/research/gallery/[id].vue").then(m => m.default || m)
  },
  {
    name: "research-gallery",
    path: "/research/gallery",
    component: () => import("/usr/src/app/pages/research/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: "research",
    path: "/research",
    component: () => import("/usr/src/app/pages/research/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-colorize-image",
    path: "/tools/colorize-image",
    component: () => import("/usr/src/app/pages/tools/colorize-image.vue").then(m => m.default || m)
  },
  {
    name: "tools-drawing-to-art",
    path: "/tools/drawing-to-art",
    component: () => import("/usr/src/app/pages/tools/drawing-to-art.vue").then(m => m.default || m)
  },
  {
    name: "tools-free-avatar",
    path: "/tools/free-avatar",
    component: () => import("/usr/src/app/pages/tools/free-avatar.vue").then(m => m.default || m)
  },
  {
    name: "tools-generate-baby",
    path: "/tools/generate-baby",
    component: () => import("/usr/src/app/pages/tools/generate-baby.vue").then(m => m.default || m)
  },
  {
    name: "tools-generate-caption",
    path: "/tools/generate-caption",
    component: () => import("/usr/src/app/pages/tools/generate-caption.vue").then(m => m.default || m)
  },
  {
    name: "tools-generate-logo-analysis",
    path: "/tools/generate-logo-analysis",
    component: () => import("/usr/src/app/pages/tools/generate-logo-analysis.vue").then(m => m.default || m)
  },
  {
    name: "tools-generate-prompt-from-image",
    path: "/tools/generate-prompt-from-image",
    component: () => import("/usr/src/app/pages/tools/generate-prompt-from-image.vue").then(m => m.default || m)
  },
  {
    name: "tools-generate-social-media-post-analysis",
    path: "/tools/generate-social-media-post-analysis",
    component: () => import("/usr/src/app/pages/tools/generate-social-media-post-analysis.vue").then(m => m.default || m)
  },
  {
    name: "tools",
    path: "/tools",
    meta: indexDoArbnIAQHMeta || {},
    component: () => import("/usr/src/app/pages/tools/index.vue").then(m => m.default || m)
  },
  {
    name: "tools-remove-background",
    path: "/tools/remove-background",
    component: () => import("/usr/src/app/pages/tools/remove-background.vue").then(m => m.default || m)
  },
  {
    name: "tools-restore-image",
    path: "/tools/restore-image",
    component: () => import("/usr/src/app/pages/tools/restore-image.vue").then(m => m.default || m)
  },
  {
    name: "tools-sticker",
    path: "/tools/sticker",
    component: () => import("/usr/src/app/pages/tools/sticker.vue").then(m => m.default || m)
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeGJTn4hmw0jMeta || {},
    component: () => import("/usr/src/app/pages/welcome.vue").then(m => m.default || m)
  }
]